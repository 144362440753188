


import { Component, Vue } from "vue-property-decorator";
import { SignUp } from "@/models/signup.model";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
@Component({
  components: {
    PreRequisiteHeader
  }
})
export default class SignUpComponent extends Vue {
  public signUp = new SignUp();
  public duplicateEmail = false;
  public invalidPassword = false;
  public successInfo: any = {};
  public email = null;
  public inProcess = false;
  
  public async validateAndRegister() {
    try {
      this.inProcess = true;
      this.successInfo = {};
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.inProcess = false;
        return;
      }
      this.signUp.email = this.signUp.email.toLowerCase();
      
      let response = await Axios.post(
        BASE_API_URL + "authentication/signUp",
        this.signUp
      );

      if (response.data.status == "duplicateEmail") {
        this.duplicateEmail = true;
        this.inProcess = false;
        return;
      }

      if (response.data.status == "invalidPassword") {
        this.invalidPassword = true;
        this.inProcess = false;
        return;
      }
      this.email = response.data.user.email;
      window.localStorage.setItem("userId", response.data.user._id);
      Vue.prototype.$userId = response.data.user._id;
      Vue.prototype.$userType = response.data.user.userType;

      // this.signUp = new SignUp();
      this.successInfo.type = "verification";
      this.successInfo.message =
        "An email has been sent to you. Please confirm email in your inbox. ";
      this.successInfo.user = response.data.user;
      this.inProcess = false;

      this.redirectToThankYouPage(this.successInfo.user._id, this.successInfo.user.email);

    } catch (error) {
      this.inProcess = false;
    }
  }

  redirectToThankYouPage(userId, userEmail) {
    this.$router.replace({
      name: 'ThankYou',
      params: {
        userId: userId,
        userEmail: userEmail,
      }
    });
  }

  public openTermsAndConditionPage() {
    window.open(location.origin + "/terms-and-conditions", "_blank");
  }

  getBrandingInformation(){
    return this.$store.state.brandingInformation || {};
  }

  mounted() {
    this.$modal.hide('reAuthenticationModal');
  }
}
